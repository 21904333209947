import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { styled } from '@mui/material/styles'
import * as actions from 'actions'
import { connect } from 'react-redux'
import moment from 'moment'
import { useSendbirdStateContext, sendBirdSelectors, Channel, App as SendBirdApp } from 'sendbird-uikit'
import InsertCommentIcon from '@material-ui/icons/InsertComment'
import RefreshIcon from '@material-ui/icons/Refresh'
import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import { useTheme } from '@mui/material/styles'
import { CVETA_SB_ACCOUNT_INFO, SUPPORT_SB_ACCOUNT_INFO } from '../../../environment'
import { StringParam, useQueryParam } from 'use-query-params'
import { Avatar, Button, IconButton, LinearProgress, Stack, TextField, Typography } from '@mui/material'
import { SortSharp } from '@mui/icons-material'
const PREFIX = 'ChatContent'

const classes = {
	root: `${PREFIX}-root`,
	roleContainer: `${PREFIX}-roleContainer`,
	separate: `${PREFIX}-separate`,
	hover: `${PREFIX}-hover`,
}

const Root = styled('div')(({ theme }) => ({
	[`&.${classes.root}`]: {
		padding: theme.spacing(4),
		height: `calc(100vh - ${theme.mixins.toolbar.minHeight * 2}px)`,
	},

	[`& .${classes.roleContainer}`]: {
		paddingTop: theme.spacing(4),
	},

	[`& .${classes.separate}`]: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(2),
	},

	[`& .${classes.hover}`]: {
		'&:hover': {
			backgroundColor: 'rgba(131, 49, 255, 0.2)',
			cursor: 'pointer',
			transition: 'all',
			transitionDuration: '0.2s',
		},
	},
}))

const ChatContent = ({
	auth,
	patient,
	symptomScore,
	summary,
	chat,
	getSortedChannelsAction,
	clearChannels,
	userKey,
	setCurrentPatient,
	mode,
	setMode,
}) => {
	const [channelParam, setChannelParam] = useQueryParam('channel', StringParam)
	const [showUnreadMessages, setShowUnreadMessages] = useState(false)
	const [channel, setChannel] = useState(channelParam)
	const theme = useTheme()
	const context = useSendbirdStateContext()
	const sdkInstance = sendBirdSelectors.getSdk(context)

	const alteredConfig = useMemo(() => {
		if (
			context.config?.userId === SUPPORT_SB_ACCOUNT_INFO ||
			context.config?.userId === CVETA_SB_ACCOUNT_INFO.userId
		) {
			return {
				...context.config,
				profileUrl: CVETA_SB_ACCOUNT_INFO.profileUrl,
				nickname: CVETA_SB_ACCOUNT_INFO.nickname,
				userId: CVETA_SB_ACCOUNT_INFO.userId,
			}
		}
		return context.config
	}, [context])

	const loadChannels = useCallback(() => {
		getSortedChannelsAction(userKey !== undefined ? userKey : context.config?.userId, '')
	})

	const loadMoreChannels = useCallback(() => {
		getSortedChannelsAction(context.config?.userId, chat.sortedChannels.next)
	})

	useEffect(() => {
		if (userKey) {
			clearChannels()
		}
		loadChannels()
	}, [])

	useEffect(() => {
		if (channel) {
			setChannelParam(channel)
		}
	}, [channel])

	const [searchValue, setSearchValue] = useState('')

	const filteredChannels = useMemo(() => {
		const channels = (chat?.sortedChannels?.channels || [])?.filter((channel) => {
			return channel?.channel?.name.toLowerCase().includes(searchValue?.toLowerCase()) 
			&& showUnreadMessages
				? channel.customBadgeCount !== 0
				: true
		}) || []
		
		return channels
	},[searchValue, chat?.sortedChannels?.channels,showUnreadMessages])

	return (
		<Root className={classes.root} style={{ padding: 0 }}>
			{mode === 'legacy' ? (
				<SendBirdApp {...alteredConfig} />
			) : (
				<>
					{chat.loading.isLoading && <LinearProgress />}
					<Stack direction={'row'} height={'100%'}>
						{userKey === undefined &&
							<Sidebar alteredConfig={alteredConfig}
							loadChannels={loadChannels}
							searchValue={searchValue}
							setSearchValue={setSearchValue}
							filteredChannels={filteredChannels}
							setChannel={setChannel}
							channel={channel}
							loadMoreChannels={loadMoreChannels}
							setCurrentPatient={setCurrentPatient}
							isLoading={chat.loading.isLoading}
							clearChannels={clearChannels}
							auth={auth}
							showUnreadMessages={showUnreadMessages}
							setShowUnreadMessages={setShowUnreadMessages}
							/>}
						{userKey !== undefined ? (
							<>
								{chat?.sortedChannels?.channels && chat?.sortedChannels?.channels?.length > 0 ? (
									MessageViewer(chat?.sortedChannels?.channels?.[0].channel_url, setCurrentPatient)
								) : (
									<Stack
										flex={1}
										style={{ backgroundColor: 'white' }}
										justifyContent={'center'}
										alignItems={'center'}
									>
										<InsertCommentIcon style={{ height: 100, width: 100, fill: '#777' }} />
										<Typography
											fontSize={20}
											fontWeight={800}
											textAlign={'center'}
											color={'GrayText'}
										>
											No chats found
										</Typography>
									</Stack>
								)}
							</>
						) : (
							MessageViewer(channel, setCurrentPatient)
						)}
					</Stack>
				</>
			)}
		</Root>
	)
}

ChatContent.propTypes = {}

const mapStateToProps = ({
	auth,
	patient,
	symptomScore,
	summary,
	chat,
	getSortedChannelsAction,
	clearChannels,
	setCurrentPatient,
}) => {
	return {
		auth,
		patient,
		symptomScore,
		summary,
		chat,
		getSortedChannelsAction,
		clearChannels,
		setCurrentPatient,
	}
}

export default connect(mapStateToProps, actions)(ChatContent)

function Sidebar(
	{alteredConfig,
	loadChannels,
	searchValue,
	setSearchValue,
	filteredChannels,
	setChannel,
	channel,
	loadMoreChannels,
	setCurrentPatient,
	isLoading,
	clearChannels,
	auth,
	showUnreadMessages,
	setShowUnreadMessages}
) {
	return (
		<Stack width={330} height={'100%'} overflow={'auto'}>
			{SidebarHeader(
				alteredConfig,
				loadChannels,
				isLoading,
				clearChannels,
				auth,
				filteredChannels?.length,
				showUnreadMessages,
				setShowUnreadMessages
			)}
			<Stack px={1.5} py={1}>
				<TextField
					style={{ flex: 1 }}
					placeholder="Search..."
					size="small"
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
			</Stack>
			{filteredChannels &&
				filteredChannels?.length > 0 &&
				filteredChannels?.filter(
					(channel) => channel?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))
				?.map((channelItem, i) =>
				<ChannelListItem key={i} channelItem={channelItem} setChannel={setChannel} channel={channel} setCurrentPatient={setCurrentPatient} onReload={loadChannels} />
				)}
			<Stack display={'flex'} justifyContent={'center'} alignItems={'center'} p={2}>
				<Button
					onClick={loadMoreChannels}
					variant="text"
					style={{ paddingLeft: 10, paddingRight: 10 }}
					disabled={isLoading}
				>
					<AutorenewIcon />{' '}
					<Typography fontSize={14} fontWeight={400} ml={1}>
						Load more
					</Typography>
				</Button>
			</Stack>
		</Stack>
	)
}

function SidebarHeader(
	alteredConfig,
	loadChannels,
	isLoading,
	clearChannels,
	auth,
	totalChats,
	showUnreadMessages,
	setShowUnreadMessages
) {
	return (
		<>
			<Stack flexDirection={'row'} p={1.5} alignItems={'center'} gap={1.5} justifyContent={'space-between'}>
				{alteredConfig?.profileUrl ? (
					<Avatar src={alteredConfig.profileUrl} style={{ height: 50, width: 50, borderRadius: 30 }} />
				) : (
					<AccountCircleIcon style={{ height: 50, width: 50, fill: '#777' }} />
				)}
				<Stack flex={1}>
					<Typography fontSize={15} fontWeight={500} color={'rebeccapurple'}>
						{alteredConfig.nickname || auth?.givenName || auth?.familyName || auth?.email}
					</Typography>
					<Typography fontSize={12} color={'GrayText'}>
						{alteredConfig.userId || auth?.sub}
					</Typography>
					{totalChats !== 0 && (
						<Typography fontSize={10} color={'GrayText'}>
							{totalChats} Channels
						</Typography>
					)}
				</Stack>

				<Stack flexDirection={'row'} alignItems={'center'} gap={'0'}>
					<IconButton
						onClick={() => {
							setShowUnreadMessages(!showUnreadMessages)
						}}
						style={{ color: showUnreadMessages ? 'rebeccapurple' : 'rgba(0,0,0,0.5)' }}
					>
						<SortSharp />
					</IconButton>
					<IconButton
						onClick={() => {
							// clearChannels()
							loadChannels()
						}}
						disabled={isLoading}
					>
						<RefreshIcon />
					</IconButton>
				</Stack>
			</Stack>
		</>
	)
}

function MessageViewer(channel, setCurrentPatient) {
	return (
		<Stack flex={1} height={'100%'} borderLeft={'1px solid rgba(0,0,0,0.1)'}>
			{channel ? (
				<Channel channelUrl={channel} disableUserProfile={false} renderUserProfile={true} />
			) : (
				<Stack flex={1} style={{ backgroundColor: 'white' }} justifyContent={'center'} alignItems={'center'}>
					<InsertCommentIcon style={{ height: 100, width: 100, fill: '#777' }} />
					<Typography fontSize={20} fontWeight={800} textAlign={'center'} color={'GrayText'}>
						Select a channel to start chatting
					</Typography>
				</Stack>
			)}
		</Stack>
	)
}

function ChannelListItem({channelItem, setChannel, channel, setCurrentPatient, onReload}) {
	
	const isUnread = useMemo(()=>{
		return channelItem.unread_message_count !== 0 && channelItem.customBadgeCount !== 0
	},[channelItem])

	return (
		<Stack
			key={channelItem.channel_url}
			className={classes.hover}
			onClick={() => {
				setChannel(channelItem.channel_url)
				onReload?.()
			}}
			gap={1}
			p={1}
			direction={'row'}
			borderBottom={'1px solid rgba(0,0,0,0.1)'}
			style={{
				borderLeft: channelItem.channel_url === channel && '5px solid rgba(131, 49, 255, 1)',
			}}
		>
			<Avatar  src={channelItem.channel?.cover_url} style={{ height: 50, width: 50, borderRadius: 30 }} />
			<Stack flex={1}>
				<Stack direction={'column'}>
					<Typography fontSize={12} fontWeight={channelItem.customBadgeCount !== 0 ? 800 : 400}>
						{channelItem.channel.name}
					</Typography>
					<Typography
						fontSize={12}
						textOverflow={'ellipsis'}
						overflow={'hidden'}
						whiteSpace={'nowrap'}
						maxWidth={220}
						color={'GrayText'}
					>
						{channelItem.last_message.message}
					</Typography>
					<Typography fontSize={10} mt={0.5}>
						{moment(channelItem.last_message.created_at).calendar()}
					</Typography>
				</Stack>
			</Stack>
			<Stack>
				{isUnread && (
					<Typography fontSize={12} fontWeight={800} color={'rebeccapurple'}>
						{channelItem.customBadgeCount} 
					</Typography>
				)}
			</Stack>
		</Stack>
	)
}
